import React from 'react';
import { useGetDoctorClinicsQuery } from '../../../redux/api/clinicApi';
import { formatTime } from '../../../utils/formatTime';

const Availability = ({ doctorId }) => {
    const { data, isLoading, isError } = useGetDoctorClinicsQuery(doctorId);

    if (isLoading) return <p>Loading...</p>;
    if (isError) return <p>Error fetching data. Please try again.</p>;

    return (
        <div>
            {data?.map((clinic) => (
                <div key={clinic?.id} className="widget business-widget">
                    <div className="widget-content mt-4">
                        <h3>{clinic?.clinicName}</h3>
                        <div className="listing-hours">
                            {clinic?.doctorTimeSlot?.map((timeSlot) => (
                                <div key={timeSlot?.id} className={`listing-day ${timeSlot?.day === 'sunday' ? 'closed' : ''}`}>
                                    <div className="listing-day">
                                        <strong>{timeSlot?.day?.charAt(0)?.toUpperCase() + timeSlot?.day?.slice(1)}</strong>
                                    </div>
                                    <div className="time-items">
                                        {timeSlot?.timeSlot?.length > 0 ? (
                                            timeSlot?.timeSlot?.map((slot) => (
                                                <span key={slot?.id} className="time">
                                                    {formatTime(slot?.startTime)} - {formatTime(slot?.endTime)}<br />
                                                </span>
                                            ))
                                        ) : (
                                            <span className="badge bg-danger-light" style={{border: '1px solid red', color: 'red'}}>Closed</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Availability;
