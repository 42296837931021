import React from 'react';
import './index.css';
import Link from 'antd/es/typography/Link';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaGithubSquare, FaPhoneAlt, FaEnvelope  } from "react-icons/fa";
import img from '../../../images/logo1.png';

const TopHeader = () => {
    
    const insta=process.env.REACT_APP_INSTAGRAM_URL;
    const facebook=process.env.REACT_APP_FACEBOOK_URL;
    const linkedin=process.env.REACT_APP_LINKEDIN_URL;
  
  
    return (
        <div id="topbar" className="d-flex align-items-center fixed-top">                    
            <div className="container d-flex justify-content-between">

                    <div className="contact-info d-flex align-items-center">
                    <a href="mailto:drdkpatwa.dermatologist@gmail.com"><FaEnvelope className='contact-icon'/><span className='d-none d-sm-inline'>drdkpatwa.dermatologist@gmail.com</span></a>
                    <a href="tel:+88 89885 55540"><FaPhoneAlt className='contact-icon'/>
                    <span className='d-none d-sm-inline'>+91 89885 55540</span></a>
                    </div>
                    <div className="d-lg-flex social-links align-items-center">
                        <a href={linkedin} target='_blank' rel="noreferrer" className="linkedin"><FaLinkedin /></a>
                        <a href={facebook} target='_blank' rel="noreferrer" className="facebook"><FaFacebookSquare /></a>
                        <a href={insta} target='_blank' rel="noreferrer" className="instagram"><FaInstagramSquare /></a>
                    </div>
                
            </div>
        </div>
    );
};
export default TopHeader;