import React from "react";
import { Link } from "react-router-dom";
import showImg from "../../../images/specialities/specialities-01.png";
import StarRatings from "react-star-ratings";
import { Tag } from "antd";
import "./index.css";
import {
  FaLocationArrow,
  FaRegThumbsUp,
  FaRupeeSign,
  FaComment,
} from "react-icons/fa";
import { truncate } from "../../../utils/truncate";
import { getUserInfo } from "../../../service/auth.service";
import { useState } from "react";
import { userRole } from "../../../constant/role";
import { useGetDoctorReviewsQuery } from "../../../redux/api/reviewsApi";
import { calculateRecommendedPercentage } from "../../../utils/DoctorPage";

const SearchContent = ({ data }) => {
  const services = data?.services?.split(",");
  const [feedback, setfeedback] = useState(0);
  const [like, setlike] = useState(0);
  const [raiting, setraiting] = useState(0);
  const localAuth = getUserInfo();
  const id = data.id;
  const {
    data: rData,
    isError: rIsError,
    isLoading: rIsLoading,
  } = useGetDoctorReviewsQuery(id);

  const totalStars =
    rData && rData.length > 0
      ? rData
          .map((item) => parseInt(item?.star || 0, 10))
          .reduce((total, star) => total + star, 0)
      : 0;
  const averageRating =
    rData && rData.length > 0 ? totalStars / rData.length : 0;

  return (
    <div className="mb-4 rounded" style={{ background: "#f3f3f3" }}>
      <div className="d-flex flex-column flex-md-row p-3 justify-content-between">
        <div className="d-flex flex-column flex-md-row gap-3">
          <div className="doc-img-fluid d-flex align-items-center">
            {data?.img && <img src={data?.img} className="" alt="User Image" />}
          </div>
          <div className="doc-info">
            <h5 className="mb-0">
              <Link to={`/doctors/profile/${data?.id}`}>
                Dr. {data?.firstName + " " + data?.lastName}
              </Link>
            </h5>
            <p className="m-0 form-text">{data?.designation}</p>
            {/* <p className="doc-department m-0"><img src={showImg} className="img-fluid" alt="Speciality" />Urology</p> */}
            <p className="doc-department m-0">{services?.[0]}</p>

            <div className="d-flex align-items-center gap-1">
              <div>
                <StarRatings
                  rating={averageRating}
                  starRatedColor="#f4c150"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  starSpacing="2px"
                />
              </div>
              <div> ({rData?.length})</div>
            </div>

            <div className="clinic-details">
              <p className="form-text text-secondary">
                <FaLocationArrow /> {data?.address}, {data?.country}
              </p>
              {/* <ul className="clinic-gallery mt-3">
                                <li>
                                    <img src={showImg} alt="Feature" style={{ maxWidth: "30px" }} />
                                </li>
                                <li>
                                    <img src={showImg} alt="Feature" style={{ maxWidth: "30px" }} />
                                </li>
                                <li>
                                    <img src={showImg} alt="Feature" style={{ maxWidth: "30px" }} />
                                </li>
                                <li>
                                    <img src={showImg} alt="Feature" style={{ maxWidth: "30px" }} />
                                </li>
                            </ul> */}
            </div>
            {services?.map((item, id) => (
              <Tag key={id + 51}>{item}</Tag>
            ))}
          </div>
        </div>
        <div className="doc-info-right me-3">
          <div className="clini-infos">
            <ul>
              <li>
                <FaRegThumbsUp /> {calculateRecommendedPercentage(rData)}%
              </li>
              <li>
                <FaComment /> {rData?.length} Feedbacks
              </li>
              <li>
                <FaLocationArrow /> {truncate(data?.clinicAddress, 20)}
              </li>
              <li>
                <FaRupeeSign /> {data?.price ? truncate(data?.price, 4) : 60}{" "}
                (Per Hour)
              </li>
            </ul>
          </div>
          <div className="clinic-booking">
            <Link to={`/doctors/profile/${data?.id}`} className="view-pro-btn">
              View Profile
            </Link>
            {localAuth && localAuth?.role !== userRole.doctor && (
              <Link to={`/booking/${data?.id}`} className="apt-btn">
                Book Appointment
              </Link>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchContent;
