import React, { useEffect } from 'react'
import useAuthCheck from '../../redux/hooks/useAuthCheck'
import { userRole } from '../../constant/role'
import { Outlet, useNavigate } from 'react-router-dom'
import NotFound from '../UI/NotFound'

function BookingOutlet() {
   
    const {role} = useAuthCheck()
   return   (role===userRole.patient||role===userRole.staff) ? <Outlet /> :<NotFound />

}

export default BookingOutlet