import DashboardLayout from '../DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { Space, Tag, Button, Empty, message, Modal } from 'antd';
import { useCreateTimeSlotMutation, useLazyGetDoctorTimeSlotQuery, useUpdateTimeSlotMutation, useDeleteTimeSlotMutation } from '../../../redux/api/timeSlotApi';
import { useGetDoctorClinicsQuery } from "../../../redux/api/clinicApi";
import { FaWindowClose, FaPlus } from "react-icons/fa";
import UseModal from '../../UI/UseModal';
import TimePicer from '../../UI/form/TimePicer';
import TabForm from '../../UI/form/TabForm';
import { isValidTimeSlot, checkOverlappingTimeSlots } from "../../../utils/checkTimeValidation";
import { getUserInfo } from '../../../service/auth.service';
import "./Schedule.css";

const Schedule = () => {
    const [key, setKey] = useState('sunday');
    const user = getUserInfo()
    const [timeSlot, setTimeSlot] = useState([]);
    const [editTimeSlot, setEditTimeSlot] = useState([]);
    const [addTimeSlot, setAddTimeSlot] = useState([]);
    const [UpdateTimeSlot, { isError: uIsError, error: uError, isLoading: UIsLoading, isSuccess: uIsSuccess }] = useUpdateTimeSlotMutation();
    const [trigger, { data, isLoading, isError }] = useLazyGetDoctorTimeSlotQuery();
    const [createTimeSlot, { isError: AIsError, error, isLoading: AIsLoading, isSuccess }] = useCreateTimeSlotMutation();
    const [deleteTimeSlot] = useDeleteTimeSlotMutation();
    const { data: clinics } = useGetDoctorClinicsQuery(user?.userId);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectClinic, setSelectClinic] = useState({});
    const [clinicId, setClinicId] = useState("");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const showModal = () => { setIsModalOpen(!isModalOpen) };
    const handleCancel = () => { setIsModalOpen(false) };
    const showEditModal = () => { setIsEditModalOpen(!isEditModalOpen) };

    useEffect(() => {
        if (clinics && clinics?.length > 0) {
            setClinicId(clinics[0].id);
            trigger({ day: key, clinicId: clinics[0].id });
        }
    }, [trigger, clinics]);

    const handleEditOk = () => {
        if (editTimeSlot.length > 0) {
            const { toCreate, toUpdate } = editTimeSlot.reduce((acc, cur) => {
                if (cur.doctorTimeSlotId) {
                    acc.toUpdate.push(cur);
                } else {
                    acc.toCreate.push({ ...cur, day: key })
                }
                return acc;
            }, { toCreate: [], toUpdate: [] });
            UpdateTimeSlot({ timeSlot: toUpdate, create: toCreate, clinicId: clinicId });
            setEditTimeSlot([]);
            trigger({ day: key, clinicId: clinicId });
            setIsEditModalOpen(UIsLoading ? true : false);
        }
    };

    useEffect(() => {
        if (!UIsLoading && uIsError) {
            message.error({
                content: 'Timeslot overlapping with ' + uError?.data?.message,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    padding: '16px 24px',
                }
            })
            setIsEditModalOpen(true);
        }
        if (uIsSuccess) {
            message.success('Successfully Slot Updated')
        }
    }, [uIsSuccess, uIsError, UIsLoading, uError?.data?.message])


    const handleEditStartTime = (id, time, timeString) => {
        const findIndex = timeSlot.find(item => item?.id === id);
        const updatedItem = { ...findIndex, startTime: timeString }
        setEditTimeSlot(prev => {
            const indexToUpdate = prev.findIndex(item => item?.id === id);
            if (indexToUpdate !== -1) {
                const updatedArray = [...prev];
                updatedArray[indexToUpdate] = updatedItem;
                return updatedArray
            } else {
                return [...prev, updatedItem]
            }
        })
    }

    const handleEditEndTime = (id, time, timeString) => {
        const findObject = timeSlot.find(item => item?.id === id);
        if (findObject) {
            const editedObject = editTimeSlot.find(item => item?.id === id);

            const updateObject = editedObject?.id ? { ...editedObject, endTime: timeString } : { ...findObject, endTime: timeString };
            setEditTimeSlot(prev => {
                const findIndex = prev.findIndex(item => item?.id === id);
                if (findIndex !== -1) {
                    const updateArray = [...prev];
                    updateArray[findIndex] = updateObject;
                    return updateArray;
                } else {
                    return [...prev, updateObject]
                }
            })
        }
    }
    const handleEditCancel = () => {
        setEditTimeSlot([]);
        if (data && data[0]?.id) {
            setTimeSlot(data[0].timeSlot)
        }
        setIsEditModalOpen(!isEditModalOpen)
    };

    const handleOk = () => {
        const timeSlot = addTimeSlot?.map(item => {
            const { id, ...rest } = item;
            return rest;
        })
        const data = {
            day: key,
            clinicId: clinicId,
            timeSlot: timeSlot
        }
        createTimeSlot({ data });
        setIsModalOpen(AIsLoading ? true : false)
    };
    useEffect(() => {
        if (!AIsLoading && AIsError) {
            message.error({
                content: 'Timeslot overlapping with ' + error?.data?.message,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    padding: '16px 24px',
                }
            })
            setIsModalOpen(true);
        }
        if (isSuccess) {
            message.success('Successfully Add Time Slots')
        }
    }, [isSuccess, AIsError, error?.data?.message, AIsLoading])

    const handleStartTime = (id, time, timeString) => {
        setAddTimeSlot(prev => (prev.map(item => item.id === id ? { ...item, startTime: timeString } : item)));
    }

    const handleEndTime = (id, time, timeString) => {
        setAddTimeSlot(prev => prev.map(item => item.id === id ? { ...item, endTime: timeString } : item));
    }
    const handleOnSelect = (value) => {
        setKey((value));
        trigger({ day: value, clinicId: clinicId });
    }

    useEffect(() => {
        if (data && data[0]?.id) {
            setTimeSlot(data[0].timeSlot)
        }
    }, [data])

    const remove = (id) => {
        setTimeSlot(timeSlot.filter((item) => item.id !== id))
        setEditTimeSlot(editTimeSlot.filter(item => item.id !== id));
        setIsDeleteModalOpen(true);
        setItemToDelete(id);
    }
    const addField = (e) => {
        const getLastValue = timeSlot.length && timeSlot[timeSlot?.length - 1];
        setTimeSlot([...timeSlot, { id: getLastValue.id + 1 }])
        setEditTimeSlot([...editTimeSlot, { id: getLastValue.id + 1 }]);
        e.preventDefault();
    }

    const removeFromAddTimeSlot = (id) => {
        setAddTimeSlot(addTimeSlot.filter((item) => item.id !== id))
    }
    const addInAddTimeSlot = (e) => {
        const newId = addTimeSlot.length + 1;
        setAddTimeSlot([...addTimeSlot, { id: newId }])
        e.preventDefault();
    }
    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setItemToDelete(null);
    }
    const handleDeleteConfirm = async () => {
        try {
            await deleteTimeSlot({ id: itemToDelete, clinicId: clinicId }).unwrap();
            message.success("Deleted successfully.");
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.log(error);
            message.error("Failed to delete.");
        }
    }
    const handleSelectChange = (e) => {
        setClinicId(e.target?.value);
        trigger({ day: key, clinicId: e.target?.value });
        setSelectClinic({ ...selectClinic, [e.target.name]: e.target.value });
    }

    let content = null;
    if (!isLoading && isError) content = <div>Something Went Wrong !</div>
    if (!isLoading && !isError && data?.length === 0) content = <Empty />
    if (!isLoading && !isError && data?.length > 0) content =
        <>
            {
                data && data.map((item, index) => (
                    <div key={item.id + index}>
                        <div>
                            {item?.maximumPatient && <h6>Maximum Patient Limit : {item?.maximumPatient}</h6>}
                        </div>
                        <Space size={[0, 'small']} wrap>
                            {
                                item?.timeSlot && item?.timeSlot.map((time, index) => (
                                    <Tag bordered={false} color="processing" key={index + 2}>
                                        {time?.startTime} - {time?.endTime}
                                    </Tag>
                                ))
                            }
                        </Space>
                    </div>
                ))
            }
        </>
    return (
        <>
            <DashboardLayout>
                <div className="clinic-and-times-container w-100 mb-3 rounded p-3" style={{ background: '#f8f9fa', height: '90vh' }}>
                    <div className='clinic-container'>
                        <h5 className='text-title'>Choose Clinic</h5>
                        <div className="col-md-10">
                            <div className="form-group mb-2 card-label">
                                <select value={selectClinic?.name} className="form-control select" onChange={handleSelectChange} name='clinic'>
                                    {clinics?.length > 0 && clinics.map((clinic) => {
                                        return <option key={clinic?.id} className='text-capitalize' value={clinic?.id}>{clinic?.clinicName}, {clinic?.addressLine1}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='times-container'>
                        <h5 className='text-title'>Schedule Timings</h5>
                        <TabForm content={content} data={data} handleOnSelect={handleOnSelect} showEditModal={showEditModal} showModal={showModal} />
                    </div>
                </div>
            </DashboardLayout>

            <UseModal title="Edit Time Slots"
                isModaOpen={isEditModalOpen}
                handleOk={handleEditOk}
                handleCancel={handleEditCancel}>
                <form>
                    <div className="hours-info">
                        <div className="row form-row hours-cont">
                            {
                                timeSlot && timeSlot?.map((item, index) => (
                                    <div className="col-12 col-md-10 d-flex align-items-center justify-content-between" key={index + item.id}>
                                        <div className="row form-row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Start Time</label>
                                                    <TimePicer handleFunction={handleEditStartTime} time={item?.startTime} id={item.id} showValue={item?.startTime !== undefined} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>End Time</label>
                                                    <TimePicer handleFunction={handleEditEndTime} time={item?.endTime} id={item.id} showValue={item?.endTime !== undefined} />
                                                </div>
                                            </div>
                                        </div>
                                        <Button type="primary" size='small' htmlType="submit"
                                            onClick={() => remove(item?.id)} block icon={<FaWindowClose />}>
                                        </Button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className=" my-2 w-25">
                        <Button type="primary" size='small' htmlType="submit" onClick={(e) => addField(e)} block icon={<FaPlus />}>
                            Add More
                        </Button>
                    </div>
                </form>
            </UseModal>

            <Modal
                title="Are you sure to delete this slot?"
                open={isDeleteModalOpen}
                onCancel={handleCloseDeleteModal}
                onOk={handleDeleteConfirm}
            />first

            <UseModal title="Add Time Slots" isModaOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}>
                <form>
                    <div className="hours-info">
                        <div className="row form-row hours-cont">
                            {
                                addTimeSlot && addTimeSlot?.map((item, index) => (
                                    <div className="col-12 col-md-10 d-flex align-items-center justify-content-between" key={index + 100}>
                                        <div className="row form-row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Start Time</label>
                                                    <TimePicer handleFunction={handleStartTime} time={item.startTime} id={item.id} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>End Time</label>
                                                    <TimePicer handleFunction={handleEndTime} time={item.endTime} id={item.id} />
                                                </div>
                                            </div>
                                        </div>
                                        <Button type="primary" size='small' htmlType="submit" onClick={() => removeFromAddTimeSlot(item?.id)} block icon={<FaWindowClose />}>
                                        </Button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className=" my-2 w-25">
                        <Button type="primary" size='small' htmlType="submit" onClick={(e) => addInAddTimeSlot(e)} block icon={<FaPlus />}>
                            Add More
                        </Button>
                    </div>
                </form>
            </UseModal>
        </>
    )
}
export default Schedule;