import React, { useState } from 'react';
import Footer from '../Shared/Footer/Footer';
import StaffContent from './StaffContent';
import { useDebounced } from '../../utils/hooks/useDebounced';
import { useGetStaffsQuery } from '../../redux/api/staffApi';
import { Empty } from 'antd';
import { Pagination } from 'antd';
import Header from '../Shared/Header/Header';
import SubHeader from '../Shared/SubHeader';

const SearchStaff = () => {
    const { data, isLoading, isError } = useGetStaffsQuery()
    
    const staffsData = data?.staff;

    //what to render
    let content = null;
    if (isLoading) content = <>Loading ...</>;
    if (!isLoading && isError) content = <div>Something Went Wrong !</div>
    if (staffsData?.length === 0) content = <div><Empty /></div>
    if (staffsData?.length > 0) content =
        <>
            {
                staffsData && staffsData?.map((item, id) => (
                    <StaffContent key={id + item.id} data={item} />
                ))
            }
        </>

    return (
        <div>
            <Header />
            <SubHeader title='staffs' subtitle='Our Expert Team of staffs' />
            <div className="container" style={{ marginBottom: 200, marginTop: 80 }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-8 col-xl-9">
                            {content}
                            <div className='text-center mt-5 mb-5'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SearchStaff