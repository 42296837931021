import React, { useState } from "react";
import { Link } from "react-router-dom";
import showImg from "../../images/img/avatar.jpg";
import "./index.css";
import { getUserInfo } from "../../service/auth.service";
import { userRole } from "../../constant/role";

const StaffContent = ({ data }) => {
  const localAuth = getUserInfo();
  return (
    <div className="mb-4 rounded" style={{ background: "#f3f3f3" }}>
      <div className="d-flex flex-column flex-md-row p-3 justify-content-between">
        <div className="d-flex flex-column flex-md-row gap-3">
          {/* Doctor's Image */}
          <div className="doc-img-fluid d-flex align-items-center">
            {data?.img ? (
              <img src={data.img} alt="Doctor" />
            ) : (
              <img src={showImg} alt="Default Doctor" />
            )}
          </div>

          
          
        </div>

        {/* Booking Button */}
       <div style={{padding:"40px"}}><div className="doc-info">
            <h5 className="mb-0">
              Dr. {data?.firstName || "First Name"} {data?.lastName || "Last Name"}
            </h5>
            <div className="clinic-details">
              <p className="form-text text-secondary">
                {data?.adharNo }
              </p>
            </div>
          </div>
       <div className="doc-info-right me-3">
          <div className="clinic-booking">
            {localAuth && localAuth?.role === userRole.staff && (
              <Link to={`/doctors`} className="apt-btn">
                Book Appointment
              </Link>
            )}
          </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default StaffContent;
