import React, { useEffect, useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBriefcase } from "react-icons/fa";
import { useGetOverviewQuery } from '../../../redux/api/overviewApi';

const OverView = ({doctorId}) => {
   
    const { data, isError, isLoading } = useGetOverviewQuery(doctorId)
  const [education , setEducation] = useState({})
  const [awards , setAwards] = useState({})
  const [experiences , setExperience] = useState({})
  const [services  , setServices] = useState([])
  const [specializations , setSpecialization] = useState([])
  const [biography, setbiography] = useState('')
console.log(data)

  useEffect(()=>{
    setEducation(data?.education)
    setAwards(data?.awards)
    setExperience(data?.experience)
    setServices(data?.services)
    setSpecialization(data?.specializations)
    setbiography(data?.aboutme?.biography);

  },[data])
    return (
        <div className="col-md-12 col-lg-9">
 
            {biography&&<div className='mb-3'>
                <h5 className='overview-text'>About Me</h5>
                <p className='text-secondary'>{biography}</p>
            </div>}

            <div>
                <h5 className='overview-text'>Education</h5>

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#2e81c4', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  #2e81c4' }}
                        date={education?.completionYear
                        }
                        iconStyle={{ background: '#2e81c4', color: '#fff' }}
                        icon={<FaBriefcase />}
                    >
                        <h5 className="text-white">{education?.degree}</h5>
                        <h6 className="text-white">{education?.college} </h6>
                        {/* <p style={{ fontSize: '14px' }}>
                            Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                        </p> */}
                    </VerticalTimelineElement>


                    

                </VerticalTimeline>

            </div>
            <div className='my-5'>
                <h5 className='overview-text'>Work & Experience</h5>

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#2e81c4', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  #2e81c4' }}
                        date={`${experiences?.from ? experiences.from : ''} - ${experiences?.to ? experiences.to : ''}`} 
                        iconStyle={{ background: '#2e81c4', color: '#fff' }}
                        icon={<FaBriefcase />}
                    >
                        <h5 className="text-white">{experiences?.hospital_name}</h5>
                        <h6 className="text-white">{experiences?.designation}</h6>
                        {/* <p style={{ fontSize: '14px' }}>
                            Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                        </p> */}
                    </VerticalTimelineElement>



                </VerticalTimeline>
                
            </div>
            <div >
                <h5 className='overview-text'>Awards</h5>

                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#2e81c4', color: '#000' }}
                        contentArrowStyle={{ borderRight: '7px solid  #2e81c4' }}
                        date="July 2019"
                        iconStyle={{ background: '#2e81c4', color: '#fff' }}
                        icon={<FaBriefcase />}
                    >
                        <h5 className="text-white">{awards?.awardName} Award</h5>
                        {/* <h6 className="text-white">Miami, FL</h6>
                        <p style={{ fontSize: '14px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p> */}
                    </VerticalTimelineElement>

                    

                </VerticalTimeline>
            </div>
            <div>
                <h5 className='overview-text'>Services</h5>
                <ul>
                {services?.map((  item , index ) =>{
                   return  <li key={index}> {item} </li>
                })}
                 
                </ul>
            </div>
            <div>
                <h5 className='overview-text'>Specializations</h5>
                <ul className="clearfix">
                {specializations?.map((  item , index ) =>{
                   return  <li key={index}> {item} </li>
                })}
                  
                </ul>
            </div>
        </div>
    )
}
export default OverView;